import jquery from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
//import "@popperjs/core";
import "bootstrap";

import "slick-carousel";
import hcSticky from "hc-sticky";
import AfterViewportJs from "@lysla/afterviewportjs";

//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".stickybar", {
    stickTo: "body",
    stickyClass: "stickybar--sticked",
  });
});
//#endregion
//#region offcavas menu mobile
$("[data-trigger]").on("click", function () {
  var trigger_id = $(this).attr("data-trigger");
  $(trigger_id).toggleClass("show");
  $("body").toggleClass("offcanvas-active");
});
$(".close-icon").on("click", function (e) {
  $("#navbarMain").removeClass("show");
  $("body").removeClass("offcanvas-active");
});
//#endregion
//#region topbar
var prevScrollpos = window.pageYOffset;
window.onscroll = function () {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("stickybar").style.top = "0";
  } else {
    document.getElementById("stickybar").style.top = "-45px";
  }
  prevScrollpos = currentScrollPos;
};
//#endregion

//#region slider
$(function () {
  $(".sliderhome").slick({
    slidesToShow: 1,
    dots: false,
    arrows: true,
    speed: 600,
    autoplay: false,
    appendArrows: $(".slider-home-arrow-container"),
    prevArrow: ".slider-home-prev-arrow",
    nextArrow: ".slider-home-next-arrow",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 4000,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 4000,
        },
      },
    ],
  });
});
//#endregion
